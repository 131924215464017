module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-C1VQGE32RQ","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"predicta-theme","short_name":"predicta","start_url":"/","background_color":"#fff","theme_color":"#222b3e","display":"minimal-ui","icon":"src/images/predicta-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc5f9c2789cd530191a2b5f55e0a1f87"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
