// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advanced-statistics-modelling-scenarios-js": () => import("./../../../src/pages/advanced-statistics/modelling-scenarios.js" /* webpackChunkName: "component---src-pages-advanced-statistics-modelling-scenarios-js" */),
  "component---src-pages-advanced-statistics-risk-analysis-js": () => import("./../../../src/pages/advanced-statistics/risk-analysis.js" /* webpackChunkName: "component---src-pages-advanced-statistics-risk-analysis-js" */),
  "component---src-pages-advanced-statistics-statistical-tests-js": () => import("./../../../src/pages/advanced-statistics/statistical-tests.js" /* webpackChunkName: "component---src-pages-advanced-statistics-statistical-tests-js" */),
  "component---src-pages-europe-conclusion-js": () => import("./../../../src/pages/europe/conclusion.js" /* webpackChunkName: "component---src-pages-europe-conclusion-js" */),
  "component---src-pages-europe-first-wave-js": () => import("./../../../src/pages/europe/first-wave.js" /* webpackChunkName: "component---src-pages-europe-first-wave-js" */),
  "component---src-pages-europe-general-findings-js": () => import("./../../../src/pages/europe/general-findings.js" /* webpackChunkName: "component---src-pages-europe-general-findings-js" */),
  "component---src-pages-europe-measures-js": () => import("./../../../src/pages/europe/measures.js" /* webpackChunkName: "component---src-pages-europe-measures-js" */),
  "component---src-pages-europe-second-wave-js": () => import("./../../../src/pages/europe/second-wave.js" /* webpackChunkName: "component---src-pages-europe-second-wave-js" */),
  "component---src-pages-europe-summer-period-js": () => import("./../../../src/pages/europe/summer-period.js" /* webpackChunkName: "component---src-pages-europe-summer-period-js" */),
  "component---src-pages-europe-vaccination-js": () => import("./../../../src/pages/europe/vaccination.js" /* webpackChunkName: "component---src-pages-europe-vaccination-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-global-analysis-js": () => import("./../../../src/pages/global-analysis.js" /* webpackChunkName: "component---src-pages-global-analysis-js" */),
  "component---src-pages-greece-a-quick-comparison-js": () => import("./../../../src/pages/greece/a-quick-comparison.js" /* webpackChunkName: "component---src-pages-greece-a-quick-comparison-js" */),
  "component---src-pages-greece-conclusion-js": () => import("./../../../src/pages/greece/conclusion.js" /* webpackChunkName: "component---src-pages-greece-conclusion-js" */),
  "component---src-pages-greece-demographics-js": () => import("./../../../src/pages/greece/demographics.js" /* webpackChunkName: "component---src-pages-greece-demographics-js" */),
  "component---src-pages-greece-general-findings-js": () => import("./../../../src/pages/greece/general-findings.js" /* webpackChunkName: "component---src-pages-greece-general-findings-js" */),
  "component---src-pages-greece-health-facilities-deaths-js": () => import("./../../../src/pages/greece/health-facilities-deaths.js" /* webpackChunkName: "component---src-pages-greece-health-facilities-deaths-js" */),
  "component---src-pages-greece-measures-mobility-js": () => import("./../../../src/pages/greece/measures-mobility.js" /* webpackChunkName: "component---src-pages-greece-measures-mobility-js" */),
  "component---src-pages-greece-success-during-the-first-wave-js": () => import("./../../../src/pages/greece/success-during-the-first-wave.js" /* webpackChunkName: "component---src-pages-greece-success-during-the-first-wave-js" */),
  "component---src-pages-greece-vaccination-effect-js": () => import("./../../../src/pages/greece/vaccination-effect.js" /* webpackChunkName: "component---src-pages-greece-vaccination-effect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latin-america-conclusion-js": () => import("./../../../src/pages/latin-america/conclusion.js" /* webpackChunkName: "component---src-pages-latin-america-conclusion-js" */),
  "component---src-pages-latin-america-general-findings-js": () => import("./../../../src/pages/latin-america/general-findings.js" /* webpackChunkName: "component---src-pages-latin-america-general-findings-js" */),
  "component---src-pages-latin-america-socio-economic-and-healts-factors-js": () => import("./../../../src/pages/latin-america/socio-economic-and-healts-factors.js" /* webpackChunkName: "component---src-pages-latin-america-socio-economic-and-healts-factors-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

